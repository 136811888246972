// eslint-disable-next-line no-redeclare,no-unused-vars
function buildSubBar(cont, subs, param, second = false) {
  var view = param.view;
  var object = param.object;
  var objectSub = param.objectSub;
  var links = {};

  var cont1 = $('<nav></nav>').insertAfter(cont);
  var cont2 = $('<div class="nav nav-tabs"></div>').appendTo(cont1);
  if (second)
    cont2.prepend('<i class="icon fa-regular fa-arrow-turn-down-right"></i>');

  $.each(subs, function (i, sub) {
    var settings = parameter[object];
    var hide = settings['hide' + ucfirst(sub)];
    if (hide && isArray(hide) && checkWhere({}, {role: hide})) return;
    else if (store['hide' + ucfirst(sub)]) return;

    var label = settings['label' + ucfirst(sub)];
    if (!label) return;

    var btn = $('<button class="nav-link">' + label + '</button>')
      .appendTo(cont2)
      .on(click, function () {
        $(this).addClass('active').siblings().removeClass('active');

        cont1.nextAll('nav').remove();
        param.objectSub = sub;
        if (!sub) sub = '';
        var subs2 = getParamSettings(param, 'subs2', null, null, null, sub);
        if (subs2) {
          var subLinks = buildSubBar(cont1, subs2, param, true);
          if (str_ends_with(sub, 'Menu')) {
            var subLink = subLinks[sub.replace('Menu', '')];
            if (!subLink) subLink = subLinks[Object.keys(subLinks)[0]];
            if (param.clickSub == sub) subLink.addClass('active');
            else return subLink.trigger(click);
          }
        }

        // call data
        if (str_ends_with(param.objectSub, 'Menu'))
          param.objectSub = param.objectSub.replace('Menu', '');
        delete viewStack[view.ident];
        if (!second) delete param.title;
        var ident = [object, sub].filter(Boolean).join('_');
        views.overview.ident = ident;
        view.ident = ident;
        param.ident = ident;
        view.attr('ident', ident);
        viewStack[ident] = view;
        var type = settings['type' + ucfirst(sub)];
        if (!type) type = 'table';
        view.update({type: type});

        if (!second) param.title = getParamSettings(param, 'label');
      });

    // open point
    var subs2 = null;
    if (param.clickSub && param.clickSub == sub) {
      btn.addClass('active');
      subs2 = getParamSettings(
        Object.assign({}, param, {objectSub: sub}),
        'subs2'
      );
      delete param.clickSub;
    } else if (sub == objectSub || (!sub && !objectSub)) {
      btn.addClass('active');
      subs2 = getParamSettings(param, 'subs2');
    }
    if (subs2) buildSubBar(cont1, subs2, param, true);

    links[sub] = btn;
  });

  return links;
}
